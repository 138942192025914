import React from "react";
import Dashboard from "./admin";
import UserLogin from "./users/login";
import { useRouter } from "next/router";

const Home = () => {
  const router = useRouter();
  if (localStorage.getItem("user")) {
    router.push("/consumer");
  } else if (!localStorage.getItem("admin")) {
    return <UserLogin />;
  }

  if (localStorage.getItem("admin")) {
    return <Dashboard />;
  }
  return <></>;
};

export default React.memo(Home);
